<template>
  <b-card
  >
    <LabelQr v-if="imageQr" :image-qr="imageQr" ticket_id="label-image"/>
    <b-modal
        hide-footer
        id="modal-prevent-closing-qr-view"
        ref="my-modal"
        :title="`Qr`"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <b-overlay
          :show="show2"
          no-wrap
      />
      <b-row>
        <b-col cols="12" lg="6">
          <div align="center">
            <img v-if="imageQr" :src="imageQr" alt="">
          </div>
          <div align="center">
            <b-button
                @click="printTicket"
                style="width: 25%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Imprimir
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div align="center" style="margin-top: 20px; font-size: 17px" v-if="infoLabel">
            Id Producto: <strong>{{infoLabel.product_id}}</strong> <br><br>
            Lote: <strong>{{infoLabel.lots}}</strong><br><br>
            Fecha de caducidad: <strong>{{infoLabel.date_expiry}}</strong><br><br>
            Código de barras : <strong>{{infoLabel.barcode ? infoLabel.barcode : ''}}</strong><br><br>
            Consecutivo: <strong>{{infoLabel.consecutive ? infoLabel.consecutive : ''}}</strong><br><br>
          </div>

        </b-col>
      </b-row>
    </b-modal>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-3" v-if="infoLabel">
      <b-col
          lg="12"
      >
        <h2>Detalles de la etiqueta</h2>
      </b-col>
      <b-col
          lg="12"
      >
        <b-row>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Consecutivo"
            >
              <b-form-input
                  disabled
                  v-model="infoLabel.consecutive"
                  placeholder="Consecutivo"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Producto"
            >
              <b-form-input
                  disabled
                  v-model="infoLabel.ProductTemplate.name"
                  placeholder="Producto"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Marca"
            >
              <b-form-input
                  disabled
                  v-model="infoLabel.brand"
                  placeholder="Marca"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Lote"
            >
              <b-form-input
                  disabled
                  v-model="infoLabel.lots"
                  placeholder="Lote"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Fecha de caducidad"
            >
              <flat-pickr
                  v-model="infoLabel.date_expiry"
                  class="form-control"
                  disabled=""
                  :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Precio de venta"
            >
              <b-form-input
                  disabled
                  v-model="infoLabel.ProductTemplate.list_price"
                  placeholder="Precio de venta"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
                label="Unidad de Medida"
            >
              <b-form-input
                  disabled
                  v-model="infoLabel.ProductTemplate.UomUom.name"
                  placeholder="Unidad de Medida"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" cols="12" class="mt-1">
            <b-row>
              <b-col lg="6" cols="12" class="mt-1">
                <router-link to="/etiquetas_medicamentos">
                  <b-button
                      style="width: 100%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1"
                  >
                    Regresar
                  </b-button>
                </router-link>
              </b-col>
              <b-col lg="6" cols="12" class="mt-1">

                <b-button
                    @click="generateQr"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Ver Qr
                </b-button>
              </b-col>
            </b-row>


          </b-col>
          <b-col lg="6" cols="12" class="mt-1">
            <div align="right">
              <router-link :to="`/editar_etiqueta/${id}`">
                <b-button
                    style="width: 35%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Editar Etiqueta
                </b-button>
              </router-link>
            </div>


          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay,
  BFormRadio

} from 'bootstrap-vue'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {VueAutosuggest} from "vue-autosuggest";
import Ripple from "vue-ripple-directive";
import {mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LabelQr from "../../components/LabelQr/LabelQr"
import jsPDF from "jspdf";

export default {
  name: "DetailLabel",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar, flatPickr, vSelect, VueAutosuggest, BOverlay, BFormRadio, LabelQr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      printInfo: false,
      infoLabel: null,
      show: false,
      show2: false,
      imageQr: null,
      id: null
    }
  },
  async created() {
    await this.findOneLabel();
  },
  methods: {
    ...mapActions('pharmacy', ['getOne', 'generateQrId']),
    async generateQr() {
      this.show = true;
      const response = await this.generateQrId(this.id);
      if (response.code) {
        this.show = false
        this.imageQr = response.code;
        await this.$bvModal.show('modal-prevent-closing-qr-view');
      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: 'error',
            variant: 'warning',
          },
        });
      }

    },
    async printTicket() {
      this.show2 = true
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      doc.setFontSize(10);
      const img = new Image()
      img.src = this.imageQr
      doc.addImage(img, 'png', 8, 1, 5, 5)
      doc.output('dataurlnewwindow');
      this.show2 = false

    },
    async resetModal() {

    },
    async findOneLabel() {
      this.show = true
      const id = this.$route.params.id;
      this.id = this.$route.params.id;
      const response = await this.getOne(id);
      if (response.data) {
        this.show = false
        this.infoLabel = response.data;
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style scoped>

</style>